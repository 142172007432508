<template>
    <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="6.93451" cy="3.73333" r="3.73333" fill="#60626E" class="hover-target-light"
            :class="{ 'active-target-light': isActive }" />
        <rect x="5.86719" y="6.93333" width="2.13333" height="3.2" fill="#60626E"
            class="hover-target-light" :class="{ 'active-target-light': isActive }" />
        <rect y="11.2" width="13.8667" height="4.8" rx="2.4" fill="#60626E"
            class="hover-target-light" :class="{ 'active-target-light': isActive }" />
        <circle cx="3.73268" cy="11.7333" r="1.06667" fill="#60626E" class="hover-target-light"
            :class="{ 'active-target-light': isActive }" />
        <circle cx="5.59961" cy="2.7" r="1" fill="#363740" />
    </svg>
</template>

<script>
    export default {
        name: 'GamesIcon',
        props: {
            isActive: Boolean
        }
    }
</script>