<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_150_18)">
            <g clip-path="url(#clip1_150_18)">
                <path fill-rule="evenodd" clip-rule="evenodd"
                    d="M2 0C0.895431 0 0 0.89543 0 2V12C0 13.1046 0.89543 14 2 14H12C13.1046 14 14 13.1046 14 12V2C14 0.895431 13.1046 0 12 0H2ZM5 2C4.44772 2 4 2.44772 4 3V11C4 11.5523 4.44772 12 5 12C5.55228 12 6 11.5523 6 11V3C6 2.44772 5.55228 2 5 2Z"
                    fill="#60626E" class="hover-target-light" :class="{ 'active-target-light': isToggle }" />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_150_18">
                <rect width="14" height="14" fill="white" />
            </clipPath>
            <clipPath id="clip1_150_18">
                <rect width="14" height="14" fill="white" />
            </clipPath>
        </defs>
    </svg>

</template>

<script>
    export default {
        name: 'ToggleIcon',
        props: {
            isToggle: Boolean
        }
    }
</script>