import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store';
import { authMixin } from './mixins/authMixin';
import './assets/sass/main.scss'

const app = createApp(App);

app.mixin(authMixin);

app.use(store);
app.use(router)


app.mount('#app');

router.options.base = '/platform/'


