<template>
  <div class="block">
    <div class="block__left-side">
      <SideMenu v-if="isLoggedIn" @toggle-sidebar="toggleSidebar"
        :class="{ 'sidebar--collapsed': isSidebarCollapsed }" />
    </div>
    <div :class="['block__main-content', { 'block__main-content--collapsed':  !isLoggedIn || isSidebarCollapsed }]">
      <HeaderBlock v-if="isLoggedIn" />
      <router-view @update-login="handleLoginUpdate" />
    </div>
  </div>



</template>

<script>


import SideMenu from './components/SideMenu.vue';
import HeaderBlock from './components/HeaderBlock.vue';
export default {
  name: 'App',
  components: {
    SideMenu,
    HeaderBlock
  },
  data() {
    return {
      isSidebarCollapsed: false
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.isLoggedIn;
    },
    shouldShowSidebar() {
      return this.$route.meta.showSidebar;
    },
  },
  methods: {
    handleLoginUpdate(status) {
      this.isLoggedIn = status;
    },
    toggleSidebar() {
      this.isSidebarCollapsed = !this.isSidebarCollapsed;
    }
  }
};

</script>