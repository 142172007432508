<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" class="hover-target-light"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M8 8C10.2094 8 12 6.20937 12 4C12 1.79063 10.2094 0 8 0C5.79063 0 4 1.79063 4 4C4 6.20937 5.79063 8 8 8ZM10.9937 9.01875L9.5 15L8.5 10.75L9.5 9H6.5L7.5 10.75L6.5 15L5.00625 9.01875C2.77812 9.125 1 10.9469 1 13.2V14.5C1 15.3281 1.67188 16 2.5 16H13.5C14.3281 16 15 15.3281 15 14.5V13.2C15 10.9469 13.2219 9.125 10.9937 9.01875V9.01875Z"
            fill="#60626E" class="hover-target-light" :class="{ 'active-target-light': isActive }" />
    </svg>
</template>

<script>
    export default {
        name: 'ProfileIcon',
        props: {
            isActive: Boolean
        }
    }
</script>