<template>
    <nav class="sidebar" :class="{ 'sidebar--collapsed': isSidebarCollapsed }">
        <div class="sidebar--top-box">
            <div class="sidebar__logo-box">
                <img :src="require('../assets/img/logo_dark_split_icon.svg')" alt="" class="sidebar__logo-box__img">
                <p class="sidebar__logo-box__text">TradeCruiser</p>
            </div>
            <ul class="sidebar__menu">
                <router-link v-for="item in menuItems" :key="item.name" :to="item.route" class="router-link">
                    <li :class="['sidebar__menu__item', 'hover-trigger', {'sidebar__menu__item--active': activeIndex === item.name}]"
                        @click="setActive(item.name)">
                        <div class="sidebar__menu__item__link__icon">
                            <component :is="item.icon" :isActive="activeIndex === item.name"></component>

                        </div>
                        <p class="sidebar__menu__item__link__text">{{item.name}}</p>
                    </li>
                </router-link>
            </ul>
        </div>

        <div class="sidebar__toggle hover-trigger" @click="toggleSidebar">

            <div class="sidebar__toggle__icon-box">
                <ToggleIcon class="sidebar__togle__icon-item"  :isToggle="isSidebarCollapsed"/>
            </div>
            <p class="sidebar__toggle__text">Toggle Menu</p>
        </div>
    </nav>
</template>

<script>
    import DashboardIcon from './icons/DashboardIcon.vue';
    import GamesIcon from './icons/GamesIcon.vue';
    import CoursesIcon from './icons/CoursesIcon.vue';
    import ProfileIcon from './icons/ProfileIcon.vue';
    import NotificationsIcon from './icons/NotificationsIcon.vue';
    import SettingsIcon from './icons/SettingsIcon.vue';

    import ToggleIcon from './icons/ToggleIcon.vue';

    export default {
        name:'SideMenu',
        data() {
            return {
                menuItems: [
                    { name: 'Dashboard', route: '/platform', icon: 'DashboardIcon' },
                    { name: 'Games', route: '/platform/games', icon: 'GamesIcon' },
                    // { name: 'Courses', route: '/courses', icon: 'CoursesIcon' },
                    // { name: 'Profile', route: '/profile', icon: 'ProfileIcon' },
                    // { name: 'Notifications', route: '/notifications', icon: 'NotificationsIcon' },
                    { name: 'Settings', route: '/platform/settings', icon: 'SettingsIcon' },
                ],
                activeIndex: null,
                isHovering: false,
                isSidebarCollapsed: false,

            }
        },
        methods: {
            setActive(index) {
                this.activeIndex = index;
            },
            toggleSidebar() {
                this.isSidebarCollapsed = !this.isSidebarCollapsed;
                this.$emit('toggle-sidebar', this.isSidebarCollapsed);
            }
        },
        components: {
            DashboardIcon,
            GamesIcon,
            CoursesIcon,
            ProfileIcon,
            NotificationsIcon,
            SettingsIcon,
            ToggleIcon
        }
    };
</script>