<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_78_31)">
            <path
                d="M15.2307 9.86557L13.8994 9.09682C14.0338 8.37182 14.0338 7.62807 13.8994 6.90307L15.2307 6.13432C15.3838 6.04682 15.4525 5.86557 15.4025 5.69682C15.0557 4.58432 14.465 3.57807 13.6932 2.74057C13.5744 2.61244 13.3807 2.58119 13.2307 2.66869L11.8994 3.43744C11.34 2.95619 10.6963 2.58432 9.9994 2.34057V0.806195C9.9994 0.631195 9.87753 0.47807 9.70565 0.44057C8.55878 0.18432 7.38378 0.19682 6.29316 0.44057C6.12128 0.47807 5.99941 0.631195 5.99941 0.806195V2.34369C5.30566 2.59057 4.66191 2.96244 4.09941 3.44057L2.77128 2.67182C2.61816 2.58432 2.42753 2.61244 2.30878 2.74369C1.53691 3.57807 0.946285 4.58432 0.59941 5.69994C0.546285 5.86869 0.61816 6.04994 0.771285 6.13744L2.10253 6.90619C1.96816 7.63119 1.96816 8.37495 2.10253 9.09995L0.771285 9.86869C0.61816 9.95619 0.54941 10.1374 0.59941 10.3062C0.946285 11.4187 1.53691 12.4249 2.30878 13.2624C2.42753 13.3906 2.62128 13.4218 2.77128 13.3343L4.10253 12.5656C4.66191 13.0468 5.30566 13.4187 6.00253 13.6624V15.1999C6.00253 15.3749 6.12441 15.5281 6.29628 15.5656C7.44316 15.8218 8.61816 15.8093 9.70878 15.5656C9.88066 15.5281 10.0025 15.3749 10.0025 15.1999V13.6624C10.6963 13.4156 11.34 13.0437 11.9025 12.5656L13.2338 13.3343C13.3869 13.4218 13.5775 13.3937 13.6963 13.2624C14.4682 12.4281 15.0588 11.4218 15.4057 10.3062C15.4525 10.1343 15.3838 9.95307 15.2307 9.86557V9.86557ZM7.99941 10.4999C6.62128 10.4999 5.49941 9.37807 5.49941 7.99994C5.49941 6.62182 6.62128 5.49994 7.99941 5.49994C9.37753 5.49994 10.4994 6.62182 10.4994 7.99994C10.4994 9.37807 9.37753 10.4999 7.99941 10.4999Z"
                fill="#60626E" class="hover-target-light"
                :class="{ 'active-target-light': isActive }" />
        </g>
        <defs>
            <clipPath id="clip0_78_31">
                <rect width="16" height="16" fill="white" />
            </clipPath>
        </defs>
    </svg>
</template>

<script>
    export default {
        name: 'SettingsIcon',
        props: {
            isActive: Boolean
        }
    }
</script>