<template>
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M9.50023 2.375C9.25977 2.375 9.02227 2.41952 8.79665 2.50539L0.469508 5.72691C0.187484 5.83822 0.000456947 6.12443 0.000456947 6.44563C0.000456947 6.76683 0.187484 7.05305 0.469508 7.16435L2.18837 7.82901C1.70151 8.6495 1.42542 9.61945 1.42542 10.6403V11.5339C1.42542 12.4371 1.10481 13.3689 0.763407 14.1035C0.570443 14.5169 0.350761 14.924 0.0954547 15.2993C0.000456948 15.436 -0.0262612 15.6141 0.0271751 15.7763C0.0806113 15.9385 0.205296 16.0593 0.359667 16.1007L2.25962 16.6095C2.38431 16.6445 2.5179 16.619 2.62774 16.5459C2.73758 16.4727 2.81476 16.3519 2.83851 16.2151C3.09382 14.854 2.96617 13.6328 2.77617 12.7583C2.68117 12.3067 2.55352 11.8456 2.3754 11.4226V10.6403C2.3754 9.67987 2.6782 8.77352 3.20366 8.04844C3.58662 7.55551 4.08239 7.15799 4.66425 6.91312L9.32508 4.95095C9.56851 4.84918 9.84459 4.97639 9.93959 5.23716C10.0346 5.49794 9.91584 5.79369 9.67241 5.89546L5.01159 7.85763C4.64347 8.01346 4.31988 8.25197 4.05567 8.54455L8.79368 10.3763C9.0193 10.4622 9.2568 10.5067 9.49726 10.5067C9.73772 10.5067 9.97522 10.4622 10.2008 10.3763L18.5309 7.16435C18.813 7.05623 19 6.76683 19 6.44563C19 6.12443 18.813 5.83822 18.5309 5.72691L10.2038 2.50539C9.97819 2.41952 9.74069 2.375 9.50023 2.375ZM3.80037 14.3325C3.80037 15.4551 6.35343 16.6222 9.50023 16.6222C12.647 16.6222 15.2001 15.4551 15.2001 14.3325L14.7459 9.7085L10.5244 11.3431C10.1949 11.4703 9.84756 11.5339 9.50023 11.5339C9.15289 11.5339 8.80259 11.4703 8.47603 11.3431L4.25457 9.7085L3.80037 14.3325Z"
            fill="#60626E" class="hover-target-light"
            :class="{ 'active-target-light': isActive }" />
    </svg>
</template>

<script>
    export default {
        name: 'CoursesIcon',
        props: {
            isActive: Boolean
        }
    }
</script>