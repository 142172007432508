<template>
    <div class="header margin-side-menu">
        <div class="header__block">
            <h1 class="header__title">{{routeName}}</h1>
        </div>
        <div class="header__block">
            <p class="header__user-name">{{userData.name || userData.email}}</p>
            <div class="header__user-icon-block" @click.stop="toggleMenu" >
                <img class="header__user-icon-block__photo" :src="userData.avatar_url || defaultAvatarURL" alt="">
                <div v-if="showMenu" class="dropdown-menu" ref="dropdownMenu" >
                    <router-link to="/settings" class="dropdown-menu__item" href="/profile-settings">Profile</router-link>
                    <a class="dropdown-menu__item" @click.prevent="logout">Logout</a>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
    import { apiCall } from '@/utils/api';
    export default {
        name: 'HeaderBlock',
        data() {
            return {
                showMenu: false,
                avatarURL: null,
                defaultAvatarURL: require('@/assets/img/user_default.jpg'),
                userData: {
                    email: '',
                    name: '',
                    avatar_url: ''
                }
            }
        },
        computed: {
            routeName() {
                return this.$route.name;
            }
        },
        methods: {
            toggleMenu() {
                this.showMenu = !this.showMenu;

                if (this.showMenu) {
                    document.addEventListener('click', this.handleClickOutside);
                } else {
                    document.removeEventListener('click', this.handleClickOutside)
                }
            },
            handleClickOutside(event) {
                if (this.$refs.dropdownMenu && !this.$refs.dropdownMenu.contains(event.target)) {
                    this.showMenu = false;
                    document.removeEventListener('click', this.handleClickOutside);
                }
            },
            logout() {
                this.$store.dispatch('setLoginState', false);
                // localStorage.removeItem('token');
                // this.$emit('update-login', false);
                this.$router.push('/login');
            },
            async getUserData() {
                try {
                    const response = await apiCall('GET', '/api/user');
                    console.log(response)
                    this.userData = response;
                } catch (error) {
                    console.error(error);
                }

            },

        },
        mounted() {
            this.getUserData();
        },
        beforeUnmount() {
            document.removeEventListener('click', this.handleClickOutside)
        }
    }
</script>