import { createRouter, createWebHistory } from 'vue-router';

const LoginPage = () => import('../views/LoginPage.vue');
const DashboardPage = () => import('../views/DashboardPage.vue');
const SettingsPage = () => import('../views/SettingsPage.vue');
const GamesPage = () => import('../views/GamesPage.vue');
const GameDetailPage = () => import('../views/GameDetailPage.vue');
const NotFoundPage = () => import('../views/NotFoundPage.vue');

const routes = [
    { path: '/platform', name: 'Dashboard', component: DashboardPage, meta: { title: 'Dashboard', showSidebar: true } },
    { path: '/login', name: 'Login', component: LoginPage, meta: { title: 'Login', showSidebar: false } },
    { path: '/platform/settings', name: 'Settings', component: SettingsPage, meta: { title: 'Settings', showSidebar: true } },
    { path: '/platform/games', name: 'Games', component: GamesPage, meta: { title: 'Games', showSidebar: true } },
    {
        path: '/platform/games/:game_url',
        name: 'GameDetail',
        component: GameDetailPage,
        props: true,
        meta: { title: 'Game Details' }
    },
    { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFoundPage, meta: { title: 'Not Found' } }
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    const publicPages = ['/login'];
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('token');

    if (authRequired && !loggedIn) {
        document.title = to.meta.title || 'TradeCruiser';
        return next('/login');
    } else if (loggedIn && to.path === '/login') {
        document.title = to.meta.title || 'TradeCruiser';
        return next('/platform');
    }

    document.title = to.meta.title || 'TradeCruiser';
    next();

})

export default router;