const getAuthToken = () => {
    return localStorage.getItem('token');
};

const apiCall = async (method, endpoint, body = null, headers ={}) => {
    // const url = `${process.env.VUE_APP_API_URL}${endpoint}`;
    const url = `${endpoint}`;
    const options = {
        method: method,
        headers: {
            'Authorization': `Bearer ${getAuthToken()}`,
            ...headers
        }
    };
    if (body) {
        options.body = body;
    }

    const response = await fetch(url, options);
    if (!response.ok) {
        const errorData = await response.json();
        const error = new Error(errorData.error || 'HTTP error!');
        error.response = { data: errorData, status: response.status };
        throw error;
        
    }
    return await response.json();
}

export { apiCall }