import { createStore } from 'vuex';

export default createStore({
    state() {
        return {
            isLoggedIn: !!localStorage.getItem('token'),
        }
    },
    mutations: {
        updateLogicState(state, status) {
            state.isLoggedIn = status;
        }
    },
    actions: {
        setLoginState({commit}, status) {
            if (status) {
                localStorage.setItem('token');
            } else {
                localStorage.removeItem('token');
            }
            commit('updateLogicState', status);
        }
    }
})